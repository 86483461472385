<template>
  <div :class="$style.root">
    <I18nLink
      :to="{name: 'index'}"
      :class="$style.link"
    >
      <CoreButtonsBaseComponent>
        <SvgoCross :class="$style.svg" />
      </CoreButtonsBaseComponent>
    </I18nLink>
    <slot />
  </div>
</template>


<style module>
.root {
  composes: font-default from global;

  display: flex;
  flex-direction: column;

  min-height: var(--100svh);

  justify-content: center;

  position: relative;
}

.link {
  position: fixed;
  top: var(--unit--default);
  right: var(--unit--default);
}

.svg {
  overflow: visible;

  margin: 0;
  padding: 0;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  overflow: visible;
  width: 45%;
  height: 45%;
}

.svg * {
  fill: none;

  stroke: currentColor;
  stroke-width: var(--core--buttons--stroke-width, var(--stroke--medium));
}
</style>
